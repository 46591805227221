@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@600&display=swap");

body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* background-image: linear-gradient(135deg, #0c0204 10%, #e7b1b2 100%);  */
  background-image: url("./Assets/red2.jpeg"); 
  
  background-repeat: round;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.Auth-form-title {
  
    text-align: center;
    margin-bottom: 1em;
    font-size: 20px;
    color: rgb(21, 21, 23);
    font-weight: 600;
  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Auth-form-content {
    padding-left: 33%;
    padding-right: 33%;
  }

  .header {
    padding-left: 83%;
    padding-right: 103%;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgba(12, 11, 11, 0.16) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: rgb(230, 219, 219);
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-error{
  font-weight: 600;
  color: red;
}



.card {
  /* Add shadows to create the "card" effect */
  width: 600px;
  height: 400px;
  background-color: rgba(255, 254, 254, 1);
  box-shadow: 0 4px 8px 0 rgb(0,0,0,0.2);
  /* border-radius: 40px */
  
}

.cardfinal {
  /* Add shadows to create the "card" effect */
  width: 600px;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
   background-color: rgba(255, 254, 254, 1);
  border-radius: 50px;
  /* padding-left: 28%;
  padding-right: 28%; */
  padding-top: 5%;
  padding-bottom: 5%;
  margin-bottom: 1em;
  
}


.card-Pg2{
  /* Add shadows to create the "card" effect */
  width: 600px;
  height: 575px;
  /* box-shadow: 0 4px 8px 0 rgba(212, 216, 233, 0.954); */
  /* background-color: rgb(255, 254, 254); */
  
  background-color: rgba(255, 254, 254, 1);
  padding-left: 28%;
  padding-right: 28%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-bottom: 1em;
  margin-top: 3em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6);
  
  /* border: none; */
  border-radius: 40px
  
  
}
.card-Pg3{
  /* Add shadows to create the "card" effect */
  width: 950px;
  height: 600px;
  /* box-shadow: 0 4px 8px 0 rgba(212, 216, 233, 0.954); */
  background-color: rgba(255, 254, 254, 1);
  /* padding-left: 33%;
  padding-right: 33%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-bottom: 1em; */

  padding: 10px 60px !important;
    height: auto !important;
    justify-content: center;
    margin: 1rem;
    margin-top: 4em;
    border-radius: 50px

}

.loginButtonSection{
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.checkstyles {  
  font-size: 10px;
  }

.btn-space {
  margin-left: 134px;
}

.custom-dialog {              
  width:48% !important; 
  top:10%;
} 

.custom-dialog2 {
  width:48% !important; 
  top:7%;
}

.changepassword{
  /* Add shadows to create the "card" effect */
  width: 900px;
  height: 600px;
  /* box-shadow: 0 4px 8px 0 rgba(212, 216, 233, 0.954); */
  background-color: rgba(255, 254, 254, 1);
  /* padding-left: 33%;
  padding-right: 33%;

  margin-bottom: 1em; */

  padding: 20px 80px !important;
    height: auto !important;
    justify-content: center;
    margin: 5rem;
    margin-top: 4em;
    border-radius: 30px
    

}

.error-message {
  color: red; /* Change the color to your desired color */
  font-size: 14px; /* Change the font size to your desired size */
}

.spacing {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically if needed */
  gap: 1rem; /* Adds space between the buttons */
  margin: 2rem 0; /* Adjust the top and bottom margins as needed */
}




